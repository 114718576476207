import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableFumeHood = loadable(() => import('components/FumeHoodCertificationPage'), {
  fallback: (
    <Spinner />
  ),
});
const FumeHoodCertification = () => {
  return <LoadableFumeHood />;
};
export default FumeHoodCertification;
